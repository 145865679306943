@use "sass:map";
/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: '~font-awesome/fonts';

$ephraim-blue: #3465aa;
$ephraim-red: #d33;
$page-header: #f8f7f3;

$light-gray: #ddd;
$social-media-bg: #eceae4;
$dark-gray: #454545;
$nav-link-color: black;
$turquoise: #007f7b;

// Main Theme Color Options
$theme-red: #c71b1b;
$theme-turquoise: #007f7b;
$theme-orange: #e27a3f;
$theme-green: #779324;
$theme-aqua: #409dae;
$theme-teal: #0cc9e8;
$theme-green-light: #8fb98b;
$theme-rust-orange: #dea154;

$theme-main: $theme-red;

$breakpoints: (
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
);

@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map.has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map.get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map.has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map.get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map.has-key($breakpoints, $lower) and map.has-key($breakpoints, $upper)
    {
        // Get the lower and upper breakpoints.
        $lower-breakpoint: map.get($breakpoints, $lower);
        $upper-breakpoint: map.get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if (map.has-key($breakpoints, $lower) == false) {
            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map.has-key($breakpoints, $upper) == false) {
            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}
