@import '../../theme/theme-variables';

.home {
    width: 100%;
    overflow-x: hidden;
    margin-top: -0.75rem;

    .carousel {
        margin-left: -5rem;
        margin-right: -5rem;
        margin-top: -0.75rem;

        .carousel-item {
            img {
                width: 95%;
                margin-left: 2%;
                height: 412px;
            }
        }
    }

    .conference-registration-btn {
        background-color: $ephraim-blue;
        position: absolute;
        top: 70%;
        left: 45%;
        font-size: 1.5rem;
        padding: 0.25rem 3rem;
        border-radius: 2rem;
    }

    .featured-blocks {
        padding: 50px 0;
        margin-bottom: 3rem;
    }

    .featured-block {
        &:hover {
            strong {
                bottom: 58px;
                transition: bottom 0.3s 0.2s;
            }
        }

        .more {
            background: #ffffff;
            color: #333333;
            display: inline-block;
            padding: 5px 20px 5px 30px;
            margin: 0;
            position: absolute;
            z-index: 9;
            left: 10px;
            bottom: 30px;
        }

        strong {
            background-color: $theme-main;
            background-image: url('../../images/pattern.png');
            color: #fff;
            font-size: 18px;
            display: inline-block;
            padding: 5px 30px;
            margin: 0;
            position: absolute;
            z-index: 10;
            left: 10px;
            bottom: 30px;
            transition: all 0.3s ease 0.1s;
        }
    }

    @include respond-above(lg) {
        .carousel .carousel-item img {
            min-height: 600px;
        }
    }

    @include respond-below(sm) {
        .conference-registration-btn {
            padding: 0 1.5rem;
            font-size: 1rem;
            top: 70%;
            left: 40%;
        }

        .carousel .carousel-item img {
            width: 85%;
            margin-left: 8%;
            height: 270px;
        }
    }

    @include respond-below(md) {
        .conference-registration-btn {
            top: 70%;
            left: 40%;
        }

        .carousel-indicators {
            bottom: -1rem;
        }
    }

    .more-events {
        padding: 24px;
    }

    .featured-sermon {
        padding: 24px;
    }

    .event-listing {
        margin-top: 24px;
        border-top: 4px solid #f8f7f3;
    }

    .sermon-container {
        margin-top: 24px;
        border-top: 4px solid #f8f7f3;
        position: relative;
        border-bottom: 0;
        margin-top: 20px;
        padding: 30px;
        background: #f8f7f3;
        position: relative;
        margin-bottom: 10px;
        min-height: 500px;
        border-top-right-radius: 35px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-bottom: 20px solid #eceae4;
            border-right: 20px solid transparent;
        }

        .fa-youtube {
            font-size: 30px;
            display: block;
            position: absolute;
            top: 26%;
            left: 40%;
            color: red;

            @include respond-below(md) {
                left: 40%;
                top: 25%;
            }

            @include respond-below(sm) {
                left: 25%;
                top: 30%;
            }

            @include respond-below(xs) {
                left: 45%;
                top: 30%;
            }
        }
    }

    .upcoming-event {
        width: 100%;
        padding: 10px 0;

        .date-container {
            display: inline-block;
            width: 6%;
            margin-right: 4%;
            width: 10%;
            font-family: 'Roboto Condensed', sans-serif;
            text-transform: uppercase;
            color: #333333;
            text-align: center;
            padding: 5px 0 0 0;

            .event-date {
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                margin-bottom: 0;
                display: block;
                margin-top: 2px;
            }

            .event-date-month {
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 2px;
                color: #999999;
                display: block;
                line-height: 0px;
            }
        }

        .summary-container {
            display: inline-block;
            width: 82%;
            margin-right: 3%;
            position: relative;
        }

        .event-summary {
            font-size: 16px;
            font-weight: 400;
            color: $theme-main;

            @include respond-below(lg) {
                font-size: 14px;
            }
        }

        .event-start-time {
            font-size: 12px;
            color: #999999;
            font-style: italic;
        }

        .event-details {
            position: absolute;
            right: 0;
            top: 0;
            font-weight: bold;
            color: black;

            &:hover {
                color: #333333;
                background-color: #ebebeb;
                border-color: #adadad;
            }

            @include respond-below(sm) {
                position: relative;
            }
        }
    }

    .sermon-title {
        color: $theme-main;
        font-size: 18x;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .sermon-thumbnail {
        width: 100%;
        height: 350px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .sermon-description {
        font-size: 16px;
        color: #666666;
    }
}
