@use '../../theme/theme-variables';

.our-ministers {
    .btn {
        background: theme-variables.$theme-main;
    }

    .card {
        width: 380px;
        margin: 15px 0px;

        &.group-ministers {
            width: 100%;

            img {
                object-fit: cover;
                object-position: top;
                height: 550px;
            }
        }

        .card-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .minister-name {
        font-weight: bold;
    }

    .minister-office {
        color: theme-variables.$theme-main;
    }

    .minister-profile-pic {
        width: 350px;
        height: 350px;
        object-fit: cover;
        object-position: top;
    }

    @include theme-variables.respond-below(lg) {
        .card {
            &.group-ministers {
                width: 100%;

                img {
                    object-fit: cover;
                    object-position: top;
                    height: 400px;
                }
            }
        }
    }

    @include theme-variables.respond-below(md) {
        .card {
            &.group-ministers {
                width: 100%;

                img {
                    object-fit: cover;
                    object-position: top;
                    height: 400px;
                }
            }

            margin: 1rem auto;
        }
    }

    @include theme-variables.respond-below(sm) {
        .card {
            &.group-ministers {
                width: 100%;

                img {
                    object-fit: cover;
                    object-position: top;
                    height: 300px;
                }
            }
        }
    }

    @include theme-variables.respond-below(xs) {
        .card {
            &.group-ministers {
                width: 100%;

                img {
                    object-fit: cover;
                    object-position: top;
                    height: 200px;
                }
            }
        }
    }
}
