/** @format */

@import '../../theme/theme-variables';

.hoe-navbar {
    margin-bottom: 0.75rem;
    background-color: white;

    .navbar .navbar-nav .dropdown-menu .nav-link {
        color: black;
    }

    .hoe-logo {
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 20rem;
        height: 11rem;
        position: absolute;
        top: 1rem;
        background-color: transparent;
        z-index: 2;
        background-image: url('../../images/rm-logo-white.png');

        &.non-home {
            background-image: url('../../images/rm-logo-black.png');
            width: 5rem;
            height: 5.25rem;
        }
    }

    .logo-row {
        background-color: $page-header;

        .nav-item {
            list-style: none;
        }
    }

    .navbar {
        background: $page-header;
        margin: 0 -1rem;
        height: 4rem;

        .navbar-nav .nav-link {
            color: $nav-link-color;
            font-size: 1.25rem;

            &:hover {
                color: $theme-main;
            }
        }

        .dropdown-item:active {
            background-color: #ddd;
        }

        .navbar-toggler {
            background: #ddd;
        }
    }

    @keyframes rotateIn {
        0% {
            transform-origin: center center;
            transform: rotate(-200deg);
            opacity: 0;
        }
        100% {
            transform-origin: center center;
            transform: rotate(0);
            opacity: 1;
        }
    }

    .social-buttons {
        background-color: $social-media-bg;
        height: 40px;

        .organization-name {
            text-indent: 6rem;
            line-height: 3rem;
            color: black;
            font-weight: bold;
            font-style: oblique;
            white-space: nowrap;
        }

        a {
            display: inline-block;
            color: $ephraim-blue;
            font-size: 20px;
            padding: 5px 20px;
            cursor: pointer;
            border-left: 1px solid rgba(256, 256, 256, 0.1);

            &:last-child {
                border-right: 1px solid rgba(256, 256, 256, 0.1);
            }

            &:hover {
                color: white;
                background-color: $ephraim-blue;
                animation-name: rotateIn;
                animation-delay: 0.1s;
                animation-fill-mode: both;
                animation-duration: 0.3s;
            }
        }
    }

    @include respond-below(lg) {
        .hoe-logo {
            width: 16rem;
            height: 9rem;
            background-image: url('../../images/rm-logo-black.png');
        }
    }

    @include respond-below(md) {
        .navbar .navbar-nav {
            .nav-link {
                font-size: 14px;
            }
        }
    }
    @include respond-below(sm) {
        .navbar-toggler {
            position: relative;
            left: 65%;
        }
        .dropdown.show .dropdown-menu {
            background: #ddd;
            border: none;
        }

        .hoe-logo {
            width: 13rem;
            height: 6rem;
            background-image: url('../../images/rm-logo-black.png');
        }

        .navbar-collapse.show {
            z-index: 20;
            background: #ddd;
            padding: 1rem;
            .nav-item a.nav-link {
                color: black;
            }
        }

        .social-buttons {
            height: 40px;
            .organization-name {
                visibility: hidden;
            }

            a {
                padding: 4px 12px;
            }
        }
    }

    @include respond-below(xs) {
        .navbar-toggler {
            position: relative;
            left: 58%;
        }
    }
}
