@import '../../theme/theme-variables';

.notice-bar {
    background: #f8f7f3;
    padding: 20px 0;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
    margin-left: -1rem;
    margin-right: -1rem;

    .container {
        position: relative;

        > .row {
            position: relative;
            z-index: 2;
        }
    }

    .notice-bar-title {
        .fa-calendar-alt {
            color: #666666;
        }
        .notice-bar-title-icon {
            float: left;
            margin-right: 1rem;
        }

        .title-note {
            background-color: $theme-main;
            margin-top: 4px;
            color: #ffffff;
            font-size: 11px;
            font-weight: 700;
            display: inline-block;
            padding: 0 5px;
            line-height: 20px;
            text-transform: uppercase;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            font-family: 'Roboto Condensed', sans-serif;
        }

        strong {
            font-size: 16px;
            color: #333;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 2px;
            display: block;
        }
    }

    .notice-bar-event-title {
        a {
            color: black;
            font-size: 16px;
            font-weight: 700;

            &:hover {
                color: $ephraim-blue;
                text-decoration: none;
            }
        }
        h5 {
            margin-bottom: 0;
        }

        .meta-data {
            display: block;
            margin-bottom: 10px;
            font-size: 12px;
            font-style: italic;
            font-family: 'Volkhov', serif;
            color: #999999;
        }
    }
    .counter {
        .timer-col {
            display: inline-block;
            width: 24%;
            text-align: center;

            .timer-type {
                font-size: 12px;
                display: block;
                margin-top: 2px;
            }

            #days,
            #minutes,
            #hours,
            #seconds {
                display: inline-block;
                font-size: 18px;
                padding: 5px 10px;
                background: $light-gray;
                border-radius: 5px;
                color: $dark-gray;
            }

            #days {
                background-color: $theme-main;
                color: white;
            }
        }
    }

    @include respond-below(md) {
        .counter {
            margin-top: 1.5rem;
        }
    }
    .notice-bar-btn {
        background-color: $theme-main;
    }
}
