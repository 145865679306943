@import '../../theme/theme-variables';

.sermon-archives {
    .sermon-card {
        background: $page-header;
        min-height: 215px;

        .video-title {
            font-size: 0.9rem;
            line-height: 1.3rem;
        }

        .video-description {
            min-height: 110px;
            text-align: center;
            font-weight: 500;
        }

        img {
            height: 100%;
            display: block;
            margin: 0 auto;
        }

        .btn {
            background: $theme-main;
        }
    }
}
