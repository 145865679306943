@import '../../theme/theme-variables';

.new-members {
    .level-category {
        margin-bottom: 2rem;
        border-bottom: 2px solid #eee;
        padding-bottom: 2rem;
    }
    .category-title {
        font-size: 20px;
        font-weight: 500;
    }
    .playlist {
        margin-left: 32px;
    }
    .playlist-thumbnail {
        width: 100px;
        height: 100px;
    }

    .resource-header {
        margin-left: 32px;
        margin-bottom: 0;
        margin-top: 5px;
    }
    .resource-link {
        margin-left: 50px;

        svg {
            color: red;
            margin-right: 10px;
        }
    }

    .subtitle-header {
        color: $theme-main;
        font-size: 1.5rem;
    }

    .subtitle {
        color: $theme-main;
    }

    .enabled-pdf-btn {
        background: $theme-main;
        color: '#fff';
    }
}
