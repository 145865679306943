@import 'theme/theme-variables';

// 3rd party libraries
@import '~bootstrap/scss/bootstrap';
//@import "~font-awesome/scss/font-awesome.scss";

// Theme customization
@import 'theme/theme';

.theme-text {
    color: $theme-main;
}

.featured-img {
    width: 100%;
    object-fit: cover;
    max-height: 600px;
    object-position: top;
    margin-bottom: 1rem;
}

.modal-content .modal-header {
    background: $theme-main;
}

.modal-header .btn-close {
    color: white;
}
