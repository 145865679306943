@use '../../theme/theme-variables';

.base-page {
    .breadcrumb {
        background-color: theme-variables.$theme-main;
        margin-top: -12px;
        border-radius: 0px;
        height: 4rem;
        padding: 1rem;

        .breadcrumb-item {
            color: white;

            &.active {
                color: black;
            }

            + .breadcrumb-item::before {
                color: white;
            }
        }
    }

    .page-title {
        position: absolute;
        right: 1rem;
        color: white;
    }

    .base-page-content {
        height: 100%;
        overflow-y: auto;
        background: white;
        padding-bottom: 2rem;
    }

    @include theme-variables.respond-below(sm) {
        .page-title {
            h4 {
                font-size: 1.25rem;
            }
        }
    }
}
