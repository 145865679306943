@use '../../theme/theme-variables';

.watch-online {
    .youtube-container {
        height: 515px;
        margin-bottom: 2rem;
    }

    @include theme-variables.respond-below(sm) {
        .youtube-container {
            height: 275px;
        }
    }

    .sermon-thumbnail {
        width: 100%;
        object-fit: cover;
        object-position: top;
    }

    .card {
        cursor: pointer;
    }

    .btn {
        background: theme-variables.$theme-main;
    }
}
