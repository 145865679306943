@use '../../theme/theme-variables';

.level-navigation {
    border-right: 1px solid theme-variables.$light-gray;
    padding-right: 12px;
    cursor: pointer;

    .level-link {
        padding: 5px 5px;
        text-align: center;

        &.active {
            background: theme-variables.$theme-main;
            color: theme-variables.$light-gray;
        }
    }

    @include theme-variables.respond-below(sm) {
        border-right: none;
    }
}
